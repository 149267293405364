import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { getPRStatusObject } from "./collaborationHelper";

export const SINGLE_SELECT_QUESTION_TYPE = 1;
export const MULTI_SELECT_QUESTION_TYPE = 2;
export const COMMENT_SELECT_QUESTION_TYPE = 3;
export const LINEAR_SELECT_QUESTION_TYPE = 5;
export const RANK_SELECT_QUESTION_TYPE = 7;
export const RANGE_SELECT_QUESTION_TYPE = 8;

export const TASTING_TYPE = "tasting";

export const REVIEW_FILTER_TYPES = {
    QUESTION_FILTER: 'question_filter',
    CURRENT_STATUS: 'current_status',
    SENSORY_TRAINED: 'sensory_trained',
    DATE: 'date',
    SHOW_INTEREST_DATE: 'show_interest_date',
    REVIEW_DATE: 'review_date',
    HOMETOWN: 'hometown',
    CURRENT_CITY: 'current_city'
};

export const removePRFalseFilters = (data) => {
    let questionFilter = [];
    if (!data) return [];
    questionFilter = data.reduce((acc, section) => {
        const sectionQuestions = section.questions.map(question => {
            const filteredOptions = question.option.filter(option => option.is_selected);
            return { ...question, option: filteredOptions };
        });

        const filteredQuestions = sectionQuestions.filter(question => question.is_selected);
        if (filteredQuestions.length > 0) {
            const sectionData = { ...section, questions: filteredQuestions };
            return [...acc, sectionData];
        }
        return acc;
    }, []);

    return [...questionFilter]
};

export const getSelectedQuestionsCount = (data) => {
    if (!data) return false;
    let questionsArray = [];
    if (Array.isArray(data)) { // handling non section survey 
        questionsArray = data.reduce((acc, section) => {
            if (Array.isArray(section.questions)) {
                return acc.concat(section.questions);
            } else {
                return acc.concat(section);
            }
        }, []);
    } else if (Array.isArray(data.questions)) {// handling section / pr questions array
        questionsArray = data.questions;
    } else {
        return false; // If data is not an array and doesn't contain a questions array, return false
    }
    const count = questionsArray.filter(question => question.is_selected).length;
    return count;
}

export const handleQuestionConditions = ({
    questionObj = null,
    currentQuestions = [],
    originalQuestions = [],
    option = null,
    currentAnswers = {},
    isSavedAnswer = false
} = {}) => {
    if (!questionObj || currentQuestions.length === 0 && originalQuestions.length === 0) {
        return currentQuestions; // Early return for empty inputs or missing questionObj
    }

    const currentOptionObject = option || {};

    const questionId = questionObj.id;
    const currentAnswerId = getCurrentAnswerId(currentAnswers, questionId);

    if (!currentAnswerId || isSavedAnswer) {// This flag checks if pre saved answers.
        // Case 1: User clicks on any option for the first time, apply conditions
        // Case 4: Apply conditions based on pre saved answers
        const newConditions = currentOptionObject.conditions || [];
        currentQuestions = applyConditions(newConditions, currentQuestions);

    } else if (!isSavedAnswer) {
        // Case 2 : User selects another option without unselecting prev option.
        const optionIsAnswered = currentAnswerId === currentOptionObject.id; // Checks if same option toggled 

        if (!optionIsAnswered) {
            // Reset conditions from the previously selected option (if any)
            currentQuestions = resetConditions(currentAnswerId, originalQuestions, currentQuestions);

            // Apply conditions from the newly selected option
            const currentConditions = get(currentOptionObject, 'conditions');
            currentQuestions = applyConditions(currentConditions, currentQuestions);
        } else {
            // Case 3: Unselecting the currently selected option: Reset conditions
            currentQuestions = resetConditions(currentAnswerId, originalQuestions, currentQuestions);

        }
    }

    return currentQuestions;

    // Helper functions
    function getCurrentAnswerId(currentAnswers, questionId) {
        return get(currentAnswers, `${questionId}.option[0].id`, null);
    }

    function applyConditions(conditions, currentQuestions) {
        return currentQuestions.map(question => {
            if (conditions.some(condition => condition.t_question_id === question.conditional_que_id)) {
                const validConditions = conditions.filter(condition => condition.t_question_id === question.conditional_que_id);
                const overriddenProps = {};
                validConditions.forEach((condition) => {
                    const overrideProps = condition.question_override;
                    for (const [key, value] of Object.entries(overrideProps)) {
                        overriddenProps[key] = typeof value === 'boolean' ? Number(value) : value;
                    }
                });
                return { ...question, ...overriddenProps };
            }
            return question;
        });
    }

    function resetConditions(prevOptionId, originalQuestions, currentQuestions) {
        const targetOption = questionObj.option.find(option => option.id === prevOptionId);

        if (targetOption) {
            const conditions = targetOption.conditions || [];
            return currentQuestions.map(current => {
                if (conditions.some(condition => condition.t_question_id === current.conditional_que_id)) {
                    const ogQuestion = originalQuestions.find(q => q.conditional_que_id === current.conditional_que_id);
                    return ogQuestion ? { ...ogQuestion } : current;
                }
                return current;
            });
        } else {
            return currentQuestions;
        }
    }
}


export const handlePreAnsweredConditions = (response) => {
    const { answer, question } = response;
    if (isEmpty(response)) return false;
    if (isEmpty(answer)) return response;

    let copiedQuestions = [...question];

    answer.forEach((answerItem) => {
        const { question_id, option } = answerItem;
        const targetQuestion = question.find(q => q.id === question_id) || [];
        const targetOption = targetQuestion && targetQuestion.option.find(o => o.id === option[0].id);
        copiedQuestions = handleQuestionConditions({
            questionObj: { ...targetQuestion },
            currentQuestions: copiedQuestions,
            originalQuestions: question,
            option: targetOption,
            currentAnswers: answerItem,
            isSavedAnswer: true
        });
    });
    return { ...response, question: copiedQuestions }
};
export const prefrenceData = (profile) => {
    const data = {
        donate: profile.is_donation === null ? "" : !!profile.is_donation ? "donate" : "self",
        preference: profile.is_donation === null ? false : true,
        terms: true,
        organization: profile.donation_organisation || {}
    }
    return data
}

export function isEmptyDemorgarphyData(obj) {
    return Object.values(obj).every(val => {
        if (typeof val === 'object' && val !== null) {
            return isEmptyDemorgarphyData(val);
        }
        return val === 0;
    });
}
export const defaultDemographyData = {
    gen_s: {
        male: 0,
        female: 0,
    },
    gen_x: {
        male: 0,
        female: 0,
    },
    millennials: {
        male: 0,
        female: 0,
    },
    gen_z: {
        male: 0,
        female: 0,
    },
    gen_a: {
        male: 0,
        female: 0,
    }
}
const citydata = (cityList) => {
    let cityIds = cityList && cityList.length && cityList.map((val, index) => parseInt(val.id));

    let emptyCityData = cityIds && cityIds.map((id) => {
        return {
            [id]: defaultDemographyData
        }
    })
    const mergedObject = emptyCityData.reduce((acc, cur) => {
        return Object.assign(acc, cur);
    }, {});
    return mergedObject;
}
export const getDemogrpahyEmptyData = (isCity, cityList) => {
    return !!isCity ? { ...citydata(cityList) } : defaultDemographyData;
}


// status & text for PR Manage section pills 

export const statusOfApplicants = [
    { type: "applicants", title: "Active" },
    {
        type: "rejected",
        title: "Rejected",
    },
    {
        type: "invited",
        title: "Invited",
    },
];

export const statusOfProductApplicants = [
    {
        type: "",
        title: "All Applicants",
        urlTitle: "",
    },
    {
        type: "0",
        title: getPRStatusObject(0).title,
    },
    {
        type: "1",
        title: getPRStatusObject(1).title,
    },
    {
        type: "2",
        title: getPRStatusObject(2).title,
    },
    {
        type: "3",
        title: getPRStatusObject(3).title,
    },
];