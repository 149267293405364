import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { login } from '../../../actions/login/login';
import { Link, withRouter } from 'react-router-dom';
import { miscLinks, homeLink, onboardingLink, feedLink } from '../../../utils/internalLinks';
import LeftArrow from '../../../assets/images/login/left-arrow.svg';
import { checkValidEmails, handleAuthState } from '../../../utils/helper';
import CloseIcon from '../../../assets/images/login/close.svg';
import EyeOpen from '../../../assets/images/login/eye-view.svg';
import EyeClose from '../../../assets/images/login/eye-close.svg';
import { passwordLength } from '../../../utils/commonVariables';
import SignupEmailOtpForm from '../SignUp/SignupEmailOtpForm';

const errorTexts = {
  failure: "Username or password you entered is incorrect.",
  email: "Please enter valid email",
};

const LoginViaEmailForm = (props) => {
  const [email, setEmail] = useState(""),
    [emailTouched, setEmailTouched] = useState(false),
    [password, setPassword] = useState(""),
    [emailError, setEmailError] = useState(false),
    [submitError, setSubmitError] = useState(""),
    [submitting, setSubmitting] = useState(false),
    [showPasswordInPlainText, setShowPasswordInPlainText] = useState(false),
    [showEmailOtpForm, setShowEmailOtpForm] = useState(false),
    [token, setToken] = useState('');
  function _isValid() {
    let isValid = true;
    setEmailError(false);
    setSubmitError("");
    if (
      !email ||
      !email.trim().length ||
      (email && !checkValidEmails(email.trim()))
    ) {
      setEmailError(true);
      isValid = false;
    }
    return isValid;
  }

  function _handleSubmit(e) {
    e.preventDefault();
    if (!_isValid() || submitting) return;
    setSubmitting(true);
    let data = {
      email: email.trim(),
      password,
    };
    const { login, history} = props;
    let goToLink;
    if (props.path) {
      goToLink = props.path;
    } else if (props.publicPageLink) {
      goToLink = props.publicPageLink;
    } else if (props.match.url) {
      goToLink = props.match.url;
    } else {
      goToLink = homeLink;
    }

    login(data).then((val) => {
      if (val.status) {
        if (val.isVerified || !!val.onBoardingComplete) {
          handleAuthState.add(val.token);
          if (!props.publicPageLink) {
            if (!val.onBoardingComplete) {
              history.replace(onboardingLink)
            } else {
              history.replace(goToLink)
            }
          }
        } else {
          setShowEmailOtpForm(true)
          setSubmitting(false);
          setToken(val.token);
        }
      } else {
        setSubmitting(false);
        setSubmitError(val.error || errorTexts.failure);
      }
    });
  }
  return (
    <div className="login-form">
      {
        showEmailOtpForm ?
          <SignupEmailOtpForm {...props} email={email} token={token} toggleEmailForm={() => setShowEmailOtpForm(false)} />
          :
          <div className="login-form__backdrop">
            {props.shouldOnlyToggle ? (
              <button type="button" className="login-form__cancel" onClick={props.toggleLoginForm}>
                <img src={CloseIcon} alt="go to homepage" />
              </button>
            ) : (
              <Link to={miscLinks.home} className="login-form__cancel">
                <img src={CloseIcon} alt="go to homepage" />
              </Link>
            )}
            <div className="login-form__container">
              <div className="login-form__header">
                <img
                  onClick={
                    props.shouldOnlyToggle && props.openSocialLogin
                      ? props.openSocialLogin
                      : props.toggleLoginForm
                  }
                  className="cursor-pointer login-form__header__icon"
                  src={LeftArrow}
                  alt="other login channels"
                />
                <p className="login-form__title">Log in via Email</p>
              </div>
              <form onSubmit={_handleSubmit} disabled={submitting} className="login-form__form">
                <div className="login-form__form__group">
                  <input
                    type="text"
                    placeholder="Email"
                    value={email}
                    autoFocus={true}
                    onBlur={() => {
                      if (emailTouched) _isValid();
                    }}
                    onChange={(e) => {
                      if (!emailTouched) setEmailTouched(true);
                      setEmail(e.target.value);
                    }}
                    className="login-form__form__input login-form__form__input--email"
                    required
                  />
                  {emailError && <p className="error-text">{errorTexts.email}</p>}
                </div>
                <div className="login-form__form__group">
                  <input
                    type={showPasswordInPlainText ? 'text' : 'password'}
                    placeholder="Password"
                    value={password}
                    maxLength={passwordLength}
                    onChange={(e) => setPassword(e.target.value)}
                    className="login-form__form__input login-form__form__input--password"
                    required
                  />
                  <button
                    type="button"
                    onClick={() => setShowPasswordInPlainText(!showPasswordInPlainText)}
                    className="login-form__form__helper-btn cursor-pointer"
                  >
                    <img src={showPasswordInPlainText ? EyeOpen : EyeClose} alt="show password" />
                  </button>
                  {!!submitError.length && <p className="error-text">{submitError}</p>}
                </div>
                <button
                  type="submit"
                  disabled={submitting || !email.length || !password.length}
                  className={`login-form__action__btn login-form__action__btn--default
                                 ${submitting || !email.length || !password.length
                      ? 'login-form__action__btn--disabled'
                      : ''
                    }`}
                >
                  Log in
                </button>
              </form>
              <Link
                className="login-form__link login-form__link--full-width login-form__link--blue"
                to={miscLinks.forgotPassword}
              >
                Forgot Password?
              </Link>
              <div>
                <p className="login-form__link">
                  Don&apos;t have an account?
                  {props.shouldOnlyToggle && props.openSignUpForm ? (
                    <button
                      onClick={props.openSignUpForm}
                      className="login-form__link login-form__link--btn login-form__link--blue"
                    >
                      Sign up
                    </button>
                  ) : (
                    <Link className="login-form__link login-form__link--blue" to={miscLinks.register}>
                      Sign up
                    </Link>
                  )}
                </p>
                <p className="login-form__helper-link">
                  By signing up on TagTaste, you are agreeing to our &nbsp;
                  <Link
                    className="login-form__helper-link login-form__helper-link--blue"
                    to={miscLinks.terms}
                  >
                    Terms &amp; Conditions
                  </Link>
                  &nbsp;and&nbsp;
                  <Link
                    className="login-form__helper-link login-form__helper-link--blue"
                    to={miscLinks.privacy}
                  >
                    Privacy Policy
                  </Link>
                </p>
              </div>
            </div>
          </div>
      }

    </div>
  );
};

LoginViaEmailForm.propTypes = {
  toggleLoginForm: PropTypes.func,
  shouldOnlyToggle: PropTypes.bool,
  publicPageLink: PropTypes.string,
  openSignUpForm: PropTypes.func,
  openSocialLogin: PropTypes.func,
  history: PropTypes.object,
  login: PropTypes.func,
};

export default withRouter(connect(null, { login})(LoginViaEmailForm));
