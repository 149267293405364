import * as linkify from "linkifyjs";
import axios from "axios";
import docImage from "../assets/images/chat/icons-chat-docx@2x.png";
import pptImage from "../assets/images/chat/icons-chat-pptx@2x.png";
import pdfImage from "../assets/images/chat/icons-chat-pdf@2x.png";
import txtImage from "../assets/images/chat/icons-chat-txt@2x.png";
import xlsImage from "../assets/images/chat/icons-chat-xls@2x.png";
import defaultImage from "../assets/images/chat/icons-chat-default-doc@2x.png";
import { ProfileUrls } from "./links";
import { hashtagLinks } from "./internalLinks.js";
import {
  genericErrorText,
  MAX_HASHTAG_LENGTH,
  readMoreLength,
} from "./commonVariables";
import { months } from "./commonVariables";
import React from "react";
import SliderArrow from "../components/reviews/common/SliderArrow";
import { screenBreakpoints } from "./commonVariables";


export const onRequest = (type, payload = {}) => {
  return {
    type,
    payload,
  };
};

export const onError = (type, error = {}, payload = {}) => {
  return {
    type,
    error,
    payload,
  };
};

export const onSuccess = (type, payload) => {
  return {
    type,
    payload,
  };
};

export const getCreatedAtString = (date) =>
  date ? new Date(date).toDateString() : "";

export const hasDivScrollEndReached = (ele) =>
  Math.floor(ele.offsetHeight) + Math.floor(ele.scrollTop) + 1 >=
  Math.floor(ele.scrollHeight);

export const pageEndReached = () =>
  window.innerHeight + window.scrollY >= document.body.offsetHeight;

export const getFileImage = (
  filesUploaded,
  images = [],
  imageFiles = [],
  captions = [],
  sizes = []
) => {
  for (let i = 0; i < filesUploaded.length; i++) {
    let tempImageObj,
      tempFile = filesUploaded[i],
      sizeObj = {
        fileWidth: 0,
        fileHeight: 0,
        fileSize: filesUploaded[i].size / 1024 / 1024, //file size in MB
      };
    tempImageObj = new Image();
    tempImageObj.onload = function () {
      sizeObj["fileWidth"] = this.width;
      sizeObj["fileHeight"] = this.height;
    };
    tempImageObj.src = URL.createObjectURL(tempFile);
    if (sizeObj.fileSize <= 10 && tempFile.type.match("image.*")) {
      images.push(URL.createObjectURL(tempFile));
      imageFiles.push(tempFile);
      captions.push("");
      sizes.push(sizeObj);
    }
  }
  let imageObj = {
    imageFiles: imageFiles,
    images: images,
    captions: captions,
    sizes: sizes,
  };
  return imageObj;
};

export const getAttachmentFile = (
  filesUploaded,
  attachmentFiles = [],
  types = [],
  fileSizes = [],
  attachmentIcons = [],
  fileNames = []
) => {
  for (let i = 0; i < filesUploaded.length; i++) {
    let tempFile = filesUploaded[i],
      fileSize = tempFile.size / 1024 / 1024,
      allowedFiles = [
        "application/msword",
        "application/vnd.ms-excel",
        "application/vnd.ms-powerpoint",
        "text/plain",
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      ];
    if (fileSize <= 10 && allowedFiles.indexOf(tempFile.type) >= 0) {
      attachmentFiles.push(tempFile);
      types.push(tempFile.type);
      fileSizes.push(fileSize); //file size in MB
      attachmentIcons.push(getFileIcon(tempFile.type));
      fileNames.push(tempFile.name);
    }
  }
  let attachmentObj = {
    attachmentFiles: attachmentFiles,
    types: types,
    fileSizes: fileSizes,
    attachmentIcons: attachmentIcons,
    fileNames: fileNames,
  };
  return attachmentObj;
};

export const getFileIcon = (fileType) => {
  let fileIcon =
    fileType.match("powerpoint") ||
      fileType.match("ppt") ||
      fileType.match("presentation")
      ? pptImage
      : fileType.match("pdf")
        ? pdfImage
        : fileType.match("plain")
          ? txtImage
          : fileType.match("excel") ||
            fileType.match("spreadsheet") ||
            fileType.match("xls") ||
            fileType.match("xlsx")
            ? xlsImage
            : fileType.match("msword") ||
              fileType.match("doc") ||
              fileType.match("docx")
              ? docImage
              : defaultImage;
  return fileIcon;
};

export const getFileData = (fileMeta) => {
  if (!fileMeta || !fileMeta.mime) {
    return false;
  }
  let fileData = fileMeta,
    mime = fileData.mime,
    size = fileData.size ? Math.floor(fileData.size * 100) / 100 : null,
    imageType =
      mime.match("ppt") ||
        mime.match("powerpoint") ||
        mime.match("presentation")
        ? pptImage
        : mime.match("pdf")
          ? pdfImage
          : mime.match("xls") ||
            mime.match("spreadsheet") ||
            mime.match("excel") ||
            mime.match("xlsx")
            ? xlsImage
            : mime.match("doc") || mime.match("msword")
              ? docImage
              : mime.match("text/plain")
                ? txtImage
                : defaultImage;
  fileData["imagePlaceholder"] = imageType;
  fileData["size"] = size;
  return fileData;
};
export const taggedText = (text, profiles) => {
  if (!text) {
    return "";
  }
  if (!profiles || (profiles && !profiles.length)) {
    return text;
  }
  const tagRegex = new RegExp(/@\[\d+:\d+]/gim), // Regex for tags
    matchingTextRegex = text.match(tagRegex),
    tagsContainsTag = !!matchingTextRegex;
  if (!tagsContainsTag) {
    // Doesn't contain any matching tags
    return text;
  }
  // Formatting string
  let str = text;
  matchingTextRegex.forEach((tag) => {
    const splittedTag = tag.split(":"), //@[profileId:index]
      profileIdToCheck = splittedTag[0]
        ? parseInt(splittedTag[0].replace("@[", ""))
        : false,
      profileIndexToReplaceWith = splittedTag[1]
        ? splittedTag[1].replace("]", "")
        : false;
    if (
      profiles[profileIndexToReplaceWith] &&
      !!profileIdToCheck &&
      profiles[profileIndexToReplaceWith].id === profileIdToCheck
    ) {
      const currentUserProfile = profiles[profileIndexToReplaceWith],
        profileUrl = ProfileUrls.userProfileLink(
          currentUserProfile.handle,
          currentUserProfile.id
        );
      const htmlToReplaceWith = `<a class="post-details__link--tagged post-details__link--inline-block" data-tagged=true href=${profileUrl}>${decodeString(
        currentUserProfile.name
      )}</a>`;
      str = str.replace(tag, htmlToReplaceWith);
    } else if (
      !!profileIdToCheck &&
      profiles.filter((_) => _.id === profileIdToCheck) &&
      profiles.filter((_) => _.id === profileIdToCheck).length
    ) {
      // doing this when index are not same
      const currentUserProfile = profiles.filter(
        (_) => _.id === profileIdToCheck
      )[0],
        profileUrl = ProfileUrls.userProfileLink(
          currentUserProfile.handle,
          currentUserProfile.id
        );
      const htmlToReplaceWith = `<a class="post-details__link--tagged post-details__link--inline-block" data-tagged=true href=${profileUrl}>${decodeString(
        currentUserProfile.name
      )}</a>`;
      str = str.replace(tag, htmlToReplaceWith);
    }
  });
  return str;
};

export const updatingStringToHashTag = (text = "") => {
  if (!text) return text;
  let str = "";
  // using the link regex from stackoverflow
  // change it if you think it's can be written in a better way
  const hashtagRegex = new RegExp(
    /#([A-Za-z0-9_]+)|((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gim
  ),
    matchingText = text.match(hashtagRegex);
  if (!matchingText) return text;
  // formatting string
  str = text.replace(hashtagRegex, (match) => {
    if (!checkValidUrl(match) && match.length <= MAX_HASHTAG_LENGTH + 1) {
      const link = hashtagLinks.feed(match.replace("#", "").trim()),
        htmlToReplace = `<a class="post-details__link" data-hashtag=true href=${link}>${match}</a>`;
      return htmlToReplace;
    }
    return match;
  });
  return str;
};

export const readMoreText = (text, readMoreLen = readMoreLength) => {
  if (!text || (text && text.length < readMoreLen)) {
    return text;
  }
  let formattedText = nl2br(text),
    splittedString = formattedText.match(
      /<(?:.|\n)*?>|(<\s*(\w+\b)(?:(?!<\s*\/\s*\1\b)[\s\S])*<\s*\/\s*\1\s*>|<br>|\S+)/gim
    ),
    hasLimitReached = false;
  // Returning if inner html as well
  let divEle = document.createElement("div");
  divEle.innerHTML = splittedString.join(" ");
  if (divEle.innerText.length < readMoreLen) {
    return text;
  }
  while (!hasLimitReached) {
    let pseudoElement = document.createElement("div");
    pseudoElement.innerHTML = splittedString.join(" ");
    let currentLength = pseudoElement.innerText.length;
    if (currentLength <= readMoreLen) {
      hasLimitReached = true;
      break;
    }
    splittedString.splice(-1, 1);
  }
  let newString = splittedString.join(" ");
  let pseudoElement = document.createElement("div");
  pseudoElement.innerHTML = newString;
  newString = pseudoElement.innerHTML;
  newString += '... <span class="read-more-link">(more)</span>';
  return newString;
};

export const nl2br = (text) => {
  if (!text) {
    return "";
  }
  return text.replace(/\r\n|\r|\n/g, " <br>");
};

export const makePlaylistForJWPlayer = (mediaJson) => {
  if (!mediaJson) {
    return;
  }
  var sources = [];
  Object.keys(mediaJson)
    .reverse()
    .forEach(function (key) {
      if (key !== "thumbnail") {
        let obj = {
          file: mediaJson[key],
          label: key,
          image: mediaJson["thumbnail"],
        };
        sources.push(obj);
      }
    });
  return sources;
};

// used to change response ds as per lib req
export const makePlaylistForReactPlayer = (mediaJson) => {
  if (!mediaJson) {
    return;
  }
  const sources = [];
  Object.keys(mediaJson)
    .reverse() // as response gives best resolution at last in object
    .forEach(function (key) {
      if (key !== "thumbnail") {
        sources.push(mediaJson[key]);
      }
    });
  return sources; // must return array with string values 
};

export const isYoutubeVideo = (text) => {
  try {
    let youtubeRegex = new RegExp(
      /((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/|shorts\/)?)([\w\-]+)(\S+)?/gim
    );
    if (text.match(youtubeRegex)) {
      return text.match(youtubeRegex) ? RegExp.$5 : false;
    }
    return false;
  } catch {
    return false;
  }
};

export const productHelper = {
  statusText: (currentStatus) => {
    return currentStatus === 1
      ? { fullText: "IN PROGRESS", text: "IN PROGRESS" }
      : currentStatus === 2
        ? { fullText: "REVIEWED BY YOU", text: "REVIEWED" }
        : { fullText: "REVIEW NOW", text: "REVIEW NOW" };
  },
  reviewText: (reviewCount, overallRatingUnavilable) => {
    if (reviewCount === 0) {
      return "No reviews";
    }
    if (overallRatingUnavilable) {
      return "Not enough reviews";
    }
    return `${reviewCount} ${reviewCount === 1 ? "Review" : "Reviews"}`;
  },
};

export const formatDate = {
  post: (postDate) => {
    if (!postDate) {
      return "";
    }
    try {
      let currentDate = Date.now(),
        timeOffset = new Date().getTimezoneOffset(),
        postDateFormatted = postDate.replace(/ /g, "T"); // Doing this for safari
      postDateFormatted = new Date(`${postDateFormatted}Z`);
      postDateFormatted = new Date(
        postDateFormatted.getTime() + timeOffset * 60 * 1000
      );
      let postDateFinal = Date.parse(postDateFormatted),
        dateDifference = currentDate - postDateFinal,
        differenceInMinutes = Math.floor(dateDifference / (1000 * 60)),
        differenceInHours = Math.floor(differenceInMinutes / 60),
        differenceInWeek = Math.floor(differenceInHours / 24);
      // Difference is within ten minutes
      if (differenceInHours <= 59) {
        if (differenceInMinutes < 1) {
          return "Just now";
        } else if (differenceInMinutes <= 59) {
          return `${differenceInMinutes} ${differenceInMinutes === 1 ? "min ago" : "mins ago"
            }`;
        }
      }
      if (differenceInHours <= 23) {
        return `${differenceInHours} ${differenceInHours === 1 ? "hour ago" : "hours ago"
          }`;
      }
      if (differenceInWeek <= 6) {
        return `${differenceInWeek} ${differenceInWeek === 1 ? "day ago" : "days ago"
          }`;
      }
      let dateOptions = {
        day: "numeric",
        month: "short",
        year: "numeric",
      };
      return new Intl.DateTimeFormat("en-GB", dateOptions).format(
        postDateFinal
      );
    } catch {
      return postDate.split(" ")[0];
    }
  },
  chat: (chatDate) => {
    let time = chatDate.split(" ")[1];
    let hour = time.split(":")[0];
    let min = time.split(":")[1];
    let part = hour > 12 ? "pm" : "am";

    min = (min + "").length === 1 ? `0${min}` : min;
    hour = hour > 12 ? hour - 12 : hour;
    hour = (hour + "").length === 1 ? `0${hour}` : hour;

    return `${hour}:${min} ${part}`;
  },
  justDate: (date, shouldNotTransform = false) => {
    const dateOptions = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    let postDateFormatted = new Date(date);
    if (!shouldNotTransform) {
      let timeOffset = new Date().getTimezoneOffset();
      postDateFormatted = date.replace(/ /g, "T"); // Doing this for safari
      postDateFormatted = new Date(`${postDateFormatted}Z`);
      postDateFormatted = new Date(
        postDateFormatted.getTime() + timeOffset * 60 * 1000
      );
    }
    return new Intl.DateTimeFormat("en-GB", dateOptions).format(
      postDateFormatted
    );
  },
};

// Function to change tagged content
export const transformTaggedContent = (content) => {
  if (!content) return ""; // no content found
  const taggingRegex = new RegExp(/@\[(.*?)\]\(\d*\)/gim),
    matchTaggingContent = content.match(taggingRegex);
  if (!matchTaggingContent) return content; // No tagging content found
  let str = content;
  matchTaggingContent.forEach((taggingTextToReplace, taggingIndex) => {
    let taggingProfileId = taggingTextToReplace.split("](")[1].replace(")", ""),
      transformedTaggingText = `@[${taggingProfileId}:${taggingIndex}]`;
    str = str.replace(taggingTextToReplace, transformedTaggingText);
  });
  return str;
};

// function to inseet new text a postion
export const insertNewProfileToTaggedContent = (content, comment) => {
  const { name, profile_id } = comment;
  // returning if blank object
  if (!content) {
    return {
      newContent: `@[${decodeString(name)}](${profile_id}) `,
    };
  }
  return {
    newContent: `${content} @[${decodeString(name)}](${profile_id}) `,
  };
};

// function to make tagged content editable
export const makeTaggedContentEditable = (content = "", profiles = []) => {
  if (!content || !profiles.length) return ""; // no content found;
  const taggedContent = new RegExp(/@\[\d+:\d+]/gim),
    matchedTaggedContent = content.match(taggedContent);
  if (!matchedTaggedContent) return content;
  let str = content;
  matchedTaggedContent.forEach((taggedTextToReplace) => {
    let splittedTaggedText = taggedTextToReplace.split(":"), //splitting the content via colon
      profileId = splittedTaggedText[0].replace("@[", ""), // getting profileId
      profileIndex = splittedTaggedText[1].replace("]", ""), // removing bracket from index
      profileToCheck = profiles[parseInt(profileIndex)];
    if (profileToCheck && parseInt(profileId) === profileToCheck.id) {
      let contentToReplaceWith = `@[${decodeString(profileToCheck.name)}](${profileToCheck.id
        })`;
      str = str.replace(taggedTextToReplace, contentToReplaceWith);
    }
  });
  return str;
};

// Exporting meta for company
export const createAPostMeta = (isCompanyAdmin = true) => {
  return {
    hasLiked: false,
    likeCount: 0,
    commentCount: 0,
    peopleLiked: [],
    shareCount: 0,
    sharedAt: null,
    isAdmin: isCompanyAdmin,
    self_vote: null, // POLL
    is_expired: 0, // POLL
    vote_count: 0, // POLL
  };
};

// function to extract response data from an axios request
export const getResponseData = (response) => {
  return response &&
    response.data &&
    response.data.errors &&
    response.data.errors.length
    ? // && Object.keys(response.data.data).length
    false
    : response.data.data;
};

// function to extract response data from an axios request
export const getResponseDataWithStatusOld = (response, isError = false) => {
  if (isError) {
    return {
      success: false,
      error: true,
      errorMsg: genericErrorText.general,
    };
  }
  return response &&
    response.data &&
    response.data.errors &&
    response.data.errors.status
    ? // && Object.keys(response.data.data).length
    {
      success: false,
      error: true,
      errors: response.data.errors,
      errorMsg:
        response.data.errors.display_message || genericErrorText.general,
    }
    : {
      success: true,
      data: response.data.data,
      error: false,
    };
};

// response data with new dataType
export const getResponseDataWithStatus = (response = {}) => {
  let { error, data } = response;
  if (!error && !data)
    return {
      status: false,
    };
  if (error && !!error.status) {
    return {
      error,
      status: false,
    };
  }
  return {
    data,
    status: true,
  };
};

// method to add / remove container class from body
export const bodyContainerClass = {
  add: () =>
    document.querySelector("body").classList.add("container", "container--bg"),
  remove: () =>
    document
      .querySelector("body")
      .classList.remove("container", "container--bg"),
};

// function to get comments id  for reducer
export const getCommentsIDForReducer = (
  postType,
  postId,
  isShared,
  sharedId,
  isAd
) =>
  isShared
    ? `${postType}-${postId}-share-${sharedId}`
    : isAd
      ? `${postType}-${postId}-ad`
      : `${postType}-${postId}`;

// function to get thumbnail of youtube
export const getYoutubeThumbnail = (youtubeId, quality = null) =>
  quality === "high"
    ? `https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg        `
    : `https://img.youtube.com/vi/${youtubeId}/default.jpg`;

// debounce function
export const debounce = (func, delay) => {
  let inDebounce;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

// function to get two decimal places
export const twoDecimal = (ele, showFull) =>
  ele ? (showFull ? ele : ele.toFixed(2)) : "00.00";

// function to get two digits
export const getTwoDigits = (num) =>
  parseInt(num).toLocaleString("en-US", { minimumIntegerDigits: 2 });

export const getDaysInMonth = (
  year = new Date().getFullYear(),
  month = new Date().getMonth() + 1
) => {
  return new Date(year, month, 0).getDate();
};

export const titleCase = (st) => {
  return st
    .toLowerCase()
    .split(" ")
    .reduce(
      (s, c) => s + "" + (c.charAt(0).toUpperCase() + c.slice(1) + " "),
      ""
    );
};

// function to get if valid email
export const checkValidEmails = (email) => linkify.test(email, "email");

// function to check if valid url
export const checkValidUrl = (link) => linkify.test(link, "url");

// checking if string is html or not
export const isTextHTML = (str) => {
  if (!str) return false;
  let dom = document.createElement("div");
  dom.innerHTML = str;
  for (var c = dom.childNodes, i = c.length; i--;) {
    if (c[i].nodeType === 1) return true;
  }
  return false;
};

// Decode String
const Entities = require("html-entities").Html5Entities,
  entities = new Entities();
export const decodeString = (str, skipTrim) => {
  if (!str) return "";
  let newStr = skipTrim ? str : str.trim();
  newStr = entities.decode(newStr);
  return newStr;
};

export const convertToPlain = (html) => {
  // Create a new div element
  var tempDivElement = document.createElement("div");
  // Set the HTML content with the given value
  tempDivElement.innerHTML = html;
  // Retrieve the text property of the element
  return tempDivElement.textContent || tempDivElement.innerText || "";
};

//get the full name of month corresponding to numeric value of month
function renderMonth(val) {
  if (!val) return "";
  if (months.filter((month) => month.numeric === val).length) {
    return months.filter((month) => month.numeric === val)[0].fullName;
  } else {
    return "";
  }
}

// function to get dates in a string
export const getDatesForView = (startDate, endDate, isPresent) => {
  let strDate = "";
  strDate += startDate
    ? startDate.split("-").length === 2
      ? `${renderMonth(startDate.split("-")[0])} ${startDate.split("-")[1]}`
      : `${startDate.split("-")[0]}`
    : "";
  strDate +=
    startDate && ((endDate && endDate !== "null") || parseInt(isPresent) === 1)
      ? " - "
      : "";
  strDate +=
    parseInt(isPresent) === 1
      ? "Present"
      : endDate && endDate !== "null"
        ? endDate.split("-").length === 2
          ? `${renderMonth(endDate.split("-")[0])} ${endDate.split("-")[1]}`
          : `${endDate.split("-")[0]}`
        : "";
  return strDate;
};

// function to check for valid name characters
export const validNameCharacters = (str) =>
  str.match(new RegExp(/^[A-zÀ-ÿ ]+$/gim));

// function to handle auth state
export const handleAuthState = {
  add: (authToken) => window.localStorage.setItem("authToken", authToken),
  remove: () => window.localStorage.removeItem("authToken"),
  get: () => window.localStorage.getItem("authToken"),
};

// function to get product liner gradient  backgorund
export const getProductBackground = (backgroundColor) =>
  `linear-gradient(67deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)), linear-gradient(to bottom, ${backgroundColor}, ${backgroundColor})`;

export const hexToRGB = (hex, alpha) => {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
};

export const sliderSettings = (
  slides = 4,
  arrowClassName,
  enableResponsiveness = true,
  customSettings = {}
) => ({
  dots: false,
  infinite: false,
  prevArrow: (
    <SliderArrow
      to="prev"
      classNameToAppend={arrowClassName || ""}
      totalSlides={slides}
    />
  ),
  nextArrow: (
    <SliderArrow
      to="next"
      classNameToAppend={arrowClassName || ""}
      totalSlides={slides}
    />
  ),
  slidesToShow: `${slides}`,
  slidesToScroll: 1,
  ...(enableResponsiveness && {
    responsive: [
      {
        breakpoint: screenBreakpoints.tab,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
    ],
  }),
  ...customSettings,
});

export const createQueryParameterFromArray = (queryParamsArray) => {
  let queryParams = `?`;
  queryParamsArray.forEach((queryParam, queryParamIndex) => {
    queryParams += `${queryParam.key}=${queryParam.value}${queryParamIndex + 1 < queryParamsArray.length ? "&" : ""
      }`;
  });
  return queryParams;
};

// function to replace ARGB to RGBA
export const convertARGBToRGBA = (argb) => {
  if (!argb) return "";
  return `#${argb.substr(3)}${argb.substr(1, 2)}`;
};

// valid hashtags
export const checkForInvalidHashTag = (string = "") => {
  return !!(
    !string ||
    (string && !string.trim()) ||
    (string && string.length === 1 && string === "#")
  );
};

// method to remove tags from a string
// it will preserver whatever is send
var striptags = require("striptags");
export const removeStripTags = (str, allowedTags) =>
  striptags(str, allowedTags);

export const logoutInterceptor = (logoutUserLocally = () => { }) => {
  // Add a request interceptor
  // axios.interceptors.request.use(function (config) {
  //   // Do something before request is sent
  //   console.log("something before request is sent")
  //   console.log(config)
  //   return config;
  // }, function (error) {
  //   // Do something with request error
  //   console.log('something with request error')
  //   return Promise.reject(error);
  // });

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      // console.log("Any status code that lie within the range of 2xx cause this function to trigger")
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      // console.log("Any status code out of 200")
      if (
        error &&
        error.response &&
        "data" in error.response &&
        typeof error.response.data === "object" &&
        "error" in error.response.data &&
        typeof error.response.data.error === "string" &&
        error.response.data.error === "token_expired"
      ) {
        logoutUserLocally();
      }
      return Promise.reject(error);
    }
  );
};

export const NewlineText = ({ text = "", className = "" }) => (
  <>
    {text.split("\\n").map((str) => (
      <p className={str.length > 0 ? className : ""}>{str}</p>
    ))}
  </>
);

// bui override default style
export const buiOverrideDefaultStyle = `.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
  color: #757575;
  left: 0px !important;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
}`;
export const buiOverrideDefaultStyleIOS = `.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
   font-weight: 300
}`;

export const GIF_TYPE = "image/gif";
export const VIDEO_TYPE = "video";

export const offsetLimits = {
  seeAll: {
    reviews: 15,
    survey: 15,
    quiz: 15,
    poll: 15,
    collaboration: 15,
    products: 15,
    workshop: 15,
  },
  explore: {
    companies: 5
  },
  feed: 5,
  hashTag: 5,
  profile: {
    all: 5,
    feed: 5,
    specialization: 5,
    reviews: 5,
    followers: 10,
    following: 10
  },
  company: {
    followers: 10
  },
  tasting: {
    myTasting: 15
  },
  survey: {
    mySurvey: 15
  },
  quiz: {
    myQuiz: 15
  },
  workshop: {
    myWorkshop: 15
  },
  chat: {
    recentList: 15
  },
  schedule: {
    remainingInvitee: 20,
    allSchedule: 20,
    inviteesList: 20
  },
  invitation: {
    myInvitation: 15,
    landingInvitations: 5
  },
  brandLimit: 10
}

export const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#\$%^&*()_+\-=[\]{};':"\\|,.<>\/?~`]).+$/;
export const newPasswordLength = 6;

export const convertSecondsToTime = (seconds) => {
  const day = 24 * 60 * 60;
  const hour = 60 * 60;
  const min = 60
  const days = Math.floor(seconds / day);
  seconds = seconds % day;

  const hours = Math.floor(seconds / hour);
  seconds = seconds % hour;

  const minutes = Math.floor(seconds / min);
  const remainingSeconds = seconds % min;

  return { day: days, hour: hours, minute: minutes };
}

export const getCombinedDate = (date, reportingTime) => {
  const [year, month, day] = date.split("-").map(Number);
  const [hours, minutes, seconds] = reportingTime.split(":").map(Number);
  const formattedDate = new Date(year, month - 1, day, hours, minutes);
  return formattedDate;
}

export const getOS = () => {
  const userAgent = window.navigator.userAgent;

  if (/Windows NT/i.test(userAgent)) {
    return 'Windows';
  } else if (/Macintosh/i.test(userAgent)) {
    return 'Mac';
  } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return 'iOS';
  } else if (/Android/i.test(userAgent)) {
    return 'Android';
  } else {
    return 'unknown';
  }
};



export const INVITATIONS_STATES = {
  PENDING: 2,
  ACCEPTED: 1,
  DECLINED: 3,
  INACTIVE: 4,
};

export const INVITATIONS_STATES_ARR = [
  {
    title: "Pending",
    state: INVITATIONS_STATES.PENDING,
  },
  {
    title: "Accepted",
    state: INVITATIONS_STATES.ACCEPTED,
  },
  {
    title: "Declined",
    state: INVITATIONS_STATES.DECLINED,
  },
  {
    title: "Inactive",
    state: INVITATIONS_STATES.INACTIVE,
  },
];


export const checkForEmptyOrUnmatchedListing = (listing = [], searchTerm = "", page = 1) => {
  let hasNoItems = false;
  let hasNoItemsMatched = false;
  if (page > 1 && listing.length === 0) {
    if (searchTerm === "") {
      hasNoItems = true;
    } else {
      hasNoItemsMatched = true;
    }
  }
  return { hasNoItems, hasNoItemsMatched };
}
export const isValidNumber = (str) => {
  let outputStr = "";
  for (const char of str.trim().replace(" ", "")) {
    if (!isNaN(char)) {
      outputStr += char
    }
  }
  return outputStr;
}
