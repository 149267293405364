import React, { useState } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { connect } from "react-redux";
import UserBar from "../common/UserBar";
import { availableFeedCardPostTypes } from "../../../../utils/commonVariables";
import CompanyBar from "../common/CompanyBar";
import MetaBar from "../common/MetaBar";
import PostComments from "../common/PostComments";
import { Link } from "react-router-dom";
import {
  GIF_TYPE,
  decodeString,
  getYoutubeThumbnail,
  isYoutubeVideo,
  makePlaylistForJWPlayer,
  removeStripTags,
} from "../../../../utils/helper";
import { surveyLinks } from "../../../../utils/internalLinks";
import ReadMoreLinkified from "../common/ReadMoreLinkified";
import ReadMoreText from "../common/ReadMoreText";
import PaidRibbon from "../../../../components/profile/MyPassbook/PaidRibbon";
import { left } from "@popperjs/core";
import CommonPlayer from "../../../../components/CommonPlayer";
function SurveyCard(props) {
  const {
    cardData,
    isShared,
    showOptionsIcon,
    hideMetaBar,
    isAd,
    showFlagOptions,
    user,
    commentsData,
  } = props,
    { surveys, meta, shared } = cardData,
    postId = get(surveys, "id"),
    createdAt = surveys.created_at,
    title = decodeString(surveys.title),
    description = removeStripTags(decodeString(surveys.description), ["br"]);
  const originalPostMeta =
    isShared && meta && meta.originalPostMeta ? meta.originalPostMeta : null,
    metaToUse = isShared ? originalPostMeta : meta,
    responseCount =
      metaToUse && metaToUse.answerCount ? metaToUse.answerCount : 0;
  const isOwner =
    user && user.user && user.user.profile
      ? surveys.company_id
        ? get(metaToUse, "isAdmin") ||
        (cardData &&
          cardData.profile &&
          user.user.profile.id === get(cardData, "profile.id"))
        : user.user.profile.id === get(cardData, "profile.id")
      : false;
  let actionText;
  if (isOwner) {
    const totalApplicants = get(surveys, "totalApplicants");
    const applicationStatus = get(metaToUse, "applicationStatus");
    if (applicationStatus === 3) {
      actionText = "RESUME SURVEY";
    } else {
      actionText =
        get(surveys, "is_private") === 1
          ? `${totalApplicants} APPLICANT${totalApplicants === 1 ? "" : "S"}`
          : `${responseCount} RESPONSE${responseCount === 1 ? "" : "S"}`;
    }
  } else {
    const isReviewed = get(metaToUse, "isReviewed") || false;
    const isPrivateSurvey = get(surveys, "is_private") === 1 ? true : false;
    const applicationStatus = get(metaToUse, "applicationStatus");
    const showFillAgainText =
      get(metaToUse, "submissionCount", 0) > 0 &&
      surveys.multi_submission === 1 &&
      metaToUse.applicationStatus === 2;
    if (isReviewed && !showFillAgainText && applicationStatus !== 3) {
      actionText = "SUBMITTED";
    } else if (showFillAgainText) {
      actionText = "FILL AGAIN";
    } else if (isPrivateSurvey && applicationStatus === 3) {
      actionText = "RESUME SURVEY";
    } else if (isPrivateSurvey) {
      actionText = "SEE MORE";
    } else {
      actionText = applicationStatus === 3 ? "RESUME SURVEY" : "FILL SURVEY";
    }
  }

  const linkToSurvey = surveyLinks.view(postId);
  const imagesMeta =
    surveys.image_meta && typeof surveys.image_meta === "string"
      ? JSON.parse(surveys.image_meta)
      : surveys.image_meta;
  const videoMeta =
    surveys.video_meta && typeof surveys.video_meta === "string"
      ? JSON.parse(surveys.video_meta)
      : surveys.video_meta;
  const uploadedVideos = get(surveys, "videos_meta", []);
  const imageToShow =
    imagesMeta && imagesMeta.length && imagesMeta[0].original_photo
      ? imagesMeta[0].original_photo
      : uploadedVideos && !!uploadedVideos.length
      ? null
      : videoMeta &&
        videoMeta.length &&
        videoMeta[0].url &&
        isYoutubeVideo(videoMeta[0].url)
        ? getYoutubeThumbnail(isYoutubeVideo(videoMeta[0].url), "high")
        : null;
  const isYoutubehas =
    videoMeta &&
      videoMeta.length &&
      videoMeta[0].url &&
      isYoutubeVideo(videoMeta[0].url)
      ? true
      : false;
  const gifImage =
    surveys.image_meta &&
      surveys.image_meta.length && surveys.image_meta[0].meta &&
      surveys.image_meta[0].meta.mime && surveys.image_meta[0].meta.mime == GIF_TYPE
      ? true
      : false;
  const CreatorBar = cardData.company ? CompanyBar : UserBar;
  const isPaid = get(meta, "isPaid");
  return (
    <div className="feed-card">
      {
        <CreatorBar
          postType={availableFeedCardPostTypes.survey}
          postId={postId}
          showOptionsIcon={showOptionsIcon}
          cardData={cardData}
          createdAt={createdAt}
          isAd={isAd}
          hideMetaBar={hideMetaBar}
          {...(cardData.company
            ? {
              profileId:
                cardData.company && cardData.company.profileId
                  ? cardData.company.profileId
                  : null,
              company: cardData.company,
            }
            : {
              profile: cardData.profile,
            })}
          showFlagOptions={showFlagOptions}
        />
      }
      <Link to={linkToSurvey} className="survey__card">
        {isPaid && (
          <div className="feedcardribbon__survey__ribbon">
            <PaidRibbon />
          </div>
        )}
        <h2 className="survey__card__header">Survey</h2>
        <ReadMoreLinkified
          text={title}
          linksAsDiv={true}
          className="survey__card__title"
          hashtagEventLabel="Survey"
        />
        <ReadMoreText
          text={description}
          className="survey__card__description"
        />
        {imageToShow ? (
          <img
            src={imageToShow}
            className={
              gifImage
                ? "survey__card__img big_gif_image background_gif "
                : "survey__card__img"
            }
            alt={title}
            style={{
              border: gifImage ? "1px solid #D1D1D1" : null,
              left: gifImage ? "-17px" : null,
              objectFit: !isYoutubehas ? "contain" : null,
            }}
          />
        ) : (
          uploadedVideos &&
          !!uploadedVideos.length &&
          uploadedVideos.map((video) => {
            const playlist = makePlaylistForJWPlayer(
              get(video, "media_json", [])
            );
            return (
              <div
                key={`feed-card-${postId}`}
                onClick={(e) => e.preventDefault()}
                className="survey__card__wrapper"
              >
                <CommonPlayer
                  playerId={`feed-card-${postId}-${isShared ? `shared-card-${shared.id}` : ''}`}
                  videosMeta={[video] || []}
                  withoverlay={false}
                />
              </div>
            );
          })
        )}
        <button className="survey__card__action">{actionText}</button>
      </Link>
      {!isShared && !hideMetaBar && cardData.meta && (
        <MetaBar
          postType={availableFeedCardPostTypes.survey}
          postId={postId}
          isAd={isAd}
          likeActionProcessing={cardData.likeActionProcessing}
          fetchingComments={commentsData && commentsData.fetching}
          postToShare={cardData}
          meta={cardData.meta}
        />
      )}
      {commentsData && !hideMetaBar && !isShared && (
        <PostComments
          showOptionsIcon={showOptionsIcon}
          commentsData={commentsData}
          postType={availableFeedCardPostTypes.survey}
          isAd={isAd}
          postId={postId}
        />
      )}
    </div>
  );
}

SurveyCard.propTypes = {
  cardData: PropTypes.object.isRequired,
  isShared: PropTypes.bool,
  commentsData: PropTypes.object,
  user: PropTypes.object,
  isAd: PropTypes.bool,
  hideMetaBar: PropTypes.bool,
  showOptionsIcon: PropTypes.bool,
  showFlagOptions: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(SurveyCard);
