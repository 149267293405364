import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GooglePlacesAutoComplete from '../../../../components/misc/GooglePlacesAutoComplete';
import ChefIcon from '../../../../assets/images/profile/completion/chef.svg';
import ProfileCompletionCompleted from './ProfileCompletionCompleted';

class LocationCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            city: props.profile.city || '',
            error: null,
            submitting: false,
            hasSubmit: !!props.profile.city
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.state.submitting) return;
        this.setState({
            error: null
        });
        const { city } = this.state;
        if (!city.trim().length) {
            this.setState({
                error: 'Please add a location'
            })
            return;
        }
        this.setState({
            submitting: true
        })
        const { profile,
            handleUserUpdate } = this.props,
            dataToSend = {
                _method: 'PATCH',
                profile: {
                    city
                }
            };
        handleUserUpdate(profile.id, dataToSend, () => {
            this.setState({
                submitting: false,
                hasSubmit: true
            })
        });
    }
    render() {
        const { submitting, hasSubmit } = this.state;
        return (<form onSubmit={this.handleSubmit} className="feed-common-cards profile-updation-data__container">
            <div className="profile-updation-data__image-container">
                <img className="profile-updation-data__image" src={ChefIcon} alt="Where do you live?" />
            </div>
            <div className="profile-updation-data__details">
                <p className="profile-updation-data__title"
                    htmlFor="location">
                    Where do you live?
                </p>
                <GooglePlacesAutoComplete
                    location={this.state.city}
                    searchOptions={{types: ['(cities)'] }}
                    handleLocationChange={(city) => (this.setState({city}))}
                    suggestionContainerClassName="profile-pill-label__suggestion__container"
                    suggestionClassName="profile-pill-label__suggestion"
                    className="profile-pill-label__city-input" />
            </div>
            <div className="profile-updation-data__btn-container">
                {
                    this.state.error
                    && <p className="profile-updation-data__eror">{this.state.error}</p>
                }
                <button type="submit" disabled={submitting}
                    className={`profile-updation-data__btn ${submitting ? 'profile-updation-data__btn--disabled' : ''}`}>Save</button>
            </div>
            { hasSubmit && <ProfileCompletionCompleted /> }
        </form>)
    }
};

LocationCard.propTypes = {
    profile: PropTypes.object,
    handleUserUpdate: PropTypes.func
}

export default LocationCard;
