import React, { useState,
    useEffect,
    useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateShippingAddress,
    addShippingAddress } from '../../../actions/profile/update';
import RemoveEntity from '../common/RemoveEntity';
import LocationIcon from '../../../assets/images/common/modals/location.svg'
import RadioOn from '../../../assets/images/profile/view/address/radio-on.svg';
import RadioOff from '../../../assets/images/profile/view/address/radio-off.svg';
import { decodeString } from '../../../utils/helper';

const errors = {
    label: 'Please enter address type',
    city: 'Please enter city',
    locality: 'Please enter locality',
    house_no: 'Please enter house number',
    pincode: 'Please enter pincode',
    state: 'Please enter state',
    landmark: 'Please enter landmark',
    country: 'Please enter country'
};

function ShippingAddressForm({ shippingAddress,
    token,
    user,
    removeAddress,
    addShippingAddress,
    handleUpdate,
    isInline,
    handleDelete,
    deleteProcessing,
    handleSave,
    updateShippingAddress }) {
    const [label, setLabel] = useState('Home'),
        [showDeleteModal, setShowDeleteModal] = useState(false),
        [processing, setProcessing] = useState(false),
        [labelError, setLabelError] = useState(false),
        [city, setCity] = useState(''),
        [cityError, setCityError] = useState(false),
        [locality, setLocality] = useState(''),
        [localityError, setLocalityError] = useState(false),
        [houseNo, setHouseNo] = useState(''),
        [houseNoError, setHouseNoError] = useState(false),
        [pincode, setPincode] = useState(''),
        [pincodeError, setPincodeError] = useState(false),
        [states, setStates] = useState(''),
        [statesError, setStatesError] = useState(false),
        [landmark, setLandmark] = useState(''),
        [country, setCountry] = useState(''),
        [countryError, setCountryError] = useState(''),
        labelRef = useRef(null),
        houseNoRef = useRef(null);
    useEffect(() => {
        if (shippingAddress) {
            setLabel(decodeString(shippingAddress.label) || 'Home');
            setCity(decodeString(shippingAddress.city) || '');
            setLocality(decodeString(shippingAddress.locality) || '');
            setHouseNo(decodeString(shippingAddress.house_no) || '');
            setPincode(decodeString(shippingAddress.pincode) || '');
            setStates(decodeString(shippingAddress.state) || '');
            setLandmark(decodeString(shippingAddress.landmark) || '');
            setCountry(decodeString(shippingAddress.country) || '');
        }
    }, []);
    // method to start browser location
    const getCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showLocationCoordinates);
        }
    }
    // getting position from lat long
    const showLocationCoordinates = (position) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode(
            {
                location:
                    {
                        lat: parseFloat(position.coords.latitude),
                        lng: parseFloat(position.coords.longitude)
                   }
            }, function(results, status) {
                if (status === 'OK') {
                    let address_components = results[0].address_components;
                    autofillInput(address_components);
                }
            })
    }
    // autofilling the inputs
    const autofillInput = (addressComponents) => {
        let address = {
            postal_code: '',
            country: '',
            sublocality: '', // Locality
            locality: '', //City
            premise: '',
            administrative_area_level_1: '' //State
        },
        directType = ['premise', 'postal_code', 'locality', 'country', 'administrative_area_level_1'],
        indirectlyAddType = ['neighborhood', 'sublocality'];
        addressComponents.forEach(function(component){
            let componentType = component.types,
                componentName = component.long_name;
            var currentType = componentType.filter(function(obj) {
                return directType.indexOf(obj) > -1;
            });
            if (currentType.length) {
                address[currentType[0]] = componentName;
            }
            var sublocalityCurrentType = componentType.filter(function(obj) {
                return indirectlyAddType.indexOf(obj) > -1;
            });
            if (sublocalityCurrentType.length) {
                address['sublocality'] += componentName + ' ';
            }
        });
        setCity(address['locality'] ? address['locality'].trim() : '');
        setLocality(address['sublocality'] ? address['sublocality'].trim()  : '');
        setHouseNo(address['premise'] ? address['premise'].trim()  : '');
        setPincode(address['postal_code'] ? address['postal_code'].trim()  : '');
        setStates(address['administrative_area_level_1'] ? address['administrative_area_level_1'].trim()  : '');
        setCountry(address['country'] ? address['country'].trim()  : '');
        if (houseNoRef.current) {
            houseNoRef.current.focus();
        }
    }
    // checking validity of form
    const checkForValid = () => {
        let isValid = true;
        setLabelError(false);
        setCityError(false);
        setLocalityError(false);
        setHouseNoError(false);
        setPincodeError(false);
        setStatesError(false);
        setCountryError(false);
        if (!label.trim()) {
            setLabelError(true);
            isValid = false;
        }
        if (!city.trim()) {
            setCityError(true);
            isValid = false;
        }
        if (!locality.trim()) {
            setLocalityError(true);
            isValid = false;
        }
        if (!houseNo.trim()) {
            setHouseNoError(true);
            isValid = false;
        }
        if (!pincode.trim()) {
            setPincodeError(true);
            isValid = false;
        }
        if (!states.trim()) {
            setStatesError(true);
            isValid = false;
        }
        if (!country.trim()) {
            setCountryError(true);
            isValid = false;
        }
        return isValid;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (processing) return;
        setProcessing(true);
        let isValid = checkForValid();
        if (!isValid) {
            setProcessing(false);
            return;
        }
        const data = {
            label,
            city,
            locality,
            house_no: houseNo,
            pincode,
            state: states,
            landmark,
            country
        }
        if (shippingAddress) {
            updateShippingAddress(token,
                user.user.profile.id,
                shippingAddress.id,
                data)
                .then(_ => {
                    setProcessing(false);
                    if (handleUpdate
                        && _
                        && typeof handleUpdate === 'function') {
                        handleUpdate();
                    }
                })
            return;
        }
        addShippingAddress(user.user.profile.id,
            data,
            token)
            .then(_ => {
                setProcessing(false);
                if (handleSave
                    && _
                    && typeof handleSave === 'function') {
                        handleSave(_);
                    }
            })
    }
    const saveButtonClass = !isInline
        ? 'profile-edit-modal__btn'
        : 'add-address__form__save',
        inputClass = isInline
            ? 'add-address__form__input'
            : 'form-input form-input--secondary';
    return (<>
    <form className="add-address add-address--full-height">
        <button type="button"
            className="add-address__current-location cursor-pointer"
            style={{
                ...(!isInline && {
                    width: '100%'
                })
            }}
            onClick={getCurrentLocation}>
            <img  className="add-address__current-location__image" src={LocationIcon} alt="location"/>
            <span className="add-address__current-location__text">Use Current Location</span>
        </button>
        <div className="add-address__form__input-container">
            <input type="text"
                name="house_no"
                value={houseNo}
                autoFocus={true}
                ref={houseNoRef}
                className={inputClass}
                required
                onChange={e => setHouseNo(e.target.value)}
                placeholder="House No. / Flat No.*" />
            {houseNoError && <p className="collaboration-form__error">{errors.house_no}</p>}
        </div>
        <div className="add-address__form__input-container">
            <input type="text"
                name="locality"
                value={locality}
                className={inputClass}
                required
                onChange={e => setLocality(e.target.value)}
                placeholder="Locality*" />
            {localityError && <p className="collaboration-form__error">{errors.locality}</p>}
        </div>
        <div className="add-address__form__input-container">
            <input type="text"
                name="landmark"
                value={landmark}
                className={inputClass}
                required
                onChange={e => setLandmark(e.target.value)}
                placeholder="Landmark" />
        </div>
        <div className="add-address__form__input-container">
            <input type="text"
                name="city"
                className={inputClass}
                value={city}
                required
                onChange={e => setCity(e.target.value)}
                placeholder="City*" />
            {cityError && <p className="collaboration-form__error">{errors.city}</p>}
        </div>
        <div className="add-address__form__input-container">
            <input type="text"
                name="state"
                className={inputClass}
                value={states}
                required
                onChange={e => setStates(e.target.value)}
                placeholder="State*" />
            {statesError && <p className="collaboration-form__error">{errors.state}</p>}
        </div>
        <div className="add-address__form__input-container">
            <input type="text"
                name="country"
                className={inputClass}
                value={country}
                required
                onChange={e => setCountry(e.target.value)}
                placeholder="Country*" />
            {countryError && <p className="collaboration-form__error">{errors.country}</p>}
        </div>
        <div className="add-address__form__input-container">
            <input type="text"
                name="pincode"
                value={pincode}
                className={inputClass}
                required
                onChange={e => setPincode(e.target.value)}
                placeholder="Pincode*" />
            {pincodeError && <p className="collaboration-form__error">{errors.pincode}</p>}
        </div>
        <div className="add-address__form__input-container">
            <p className="add-address__form__type__label">Address Type</p>
            <div className="add-address__form__type__container">
                <label name="label">
                    <input value="Home"
                        onChange={() => setLabel('Home')}
                        checked={label === 'Home'}
                        type="radio" />
                    <img src={label.trim() === 'Home' ? RadioOn : RadioOff} />
                    <span>Home</span>
                </label>
                <label name="label">
                    <input value="Home"
                        onChange={() => setLabel('Office')}
                        checked={label.trim() === 'Office'}
                        type="radio" />
                    <img src={label.trim() === 'Office' ? RadioOn : RadioOff} />
                    <span>Office</span>
                </label>
                <label name="label">
                    <input value="Home"
                        onChange={() => {
                            setLabel(shippingAddress
                                && shippingAddress.label
                                && shippingAddress.label !== 'Home'
                                && shippingAddress.label !== 'Office'
                                ? decodeString(shippingAddress.label)
                                : '');
                            setTimeout(() => {
                                if (labelRef.current) {
                                    labelRef.current.focus();
                                }
                            }, 0);
                        }}
                        checked={label.trim() !== 'Home' && label.trim() !== 'Office'}
                        type="radio" />
                    <img src={(label.trim() !== 'Office' && label.trim() !== 'Home') ? RadioOn : RadioOff} />
                    <span>Other</span>
                </label>
            </div>
            {
                label !== 'Home' && label !== 'Office'
                && <div className="add-address__form__input-container">
                    <input type="text"
                        ref={labelRef}
                        name="label"
                        value={label}
                        className={inputClass}
                        required
                        onChange={e => setLabel(e.target.value)}
                        placeholder="PO Box, Friend's Home*" />
                    {labelError && <p className="collaboration-form__error">{errors.label}</p>}
                </div>
            }
        </div>
        <div className={isInline
                ? ''
                : `default-modal__footer ${removeAddress ? 'default-modal__footer--multi' : ''} default-modal__footer--bottom`}>
            {removeAddress
                && handleDelete
                && <button className={`default-modal__footer__clear-btn default-modal__footer__clear-btn--no-margin ${deleteProcessing ? 'default-modal__footer__clear-btn--disabled' : ''} default-modal__footer__clear-btn--remove`}
                disabled={deleteProcessing}
                onClick={() => handleDelete(shippingAddress.id)}
                type="button">Remove Address</button> }
            <button className={`${saveButtonClass} ${processing ? saveButtonClass+'--disabled' : ''}`}
                type="submit"
                disabled={processing}
                onClick={handleSubmit}>Save</button>
        </div>
    </form>
    {
        showDeleteModal
        && <RemoveEntity handleClick={handleDelete}
            title="Remove Address"
            description="Are you sure you want to remove the address?"
            removing={processing}
            handleClose={() => setShowDeleteModal(false)} />
    }
    </>)
};

ShippingAddressForm.propTypes = {
    shippingAddress: PropTypes.object,
    removeAddress: PropTypes.bool,
    handleSave: PropTypes.func,
    isInline: PropTypes.bool,
    handleUpdate: PropTypes.func,
    handleDelete: PropTypes.func,
    deleteProcessing: PropTypes.bool
}

function mapStateToProps(state) {
    return {
        token: state.login.token,
        user: state.user
    }
}

const mapDispatchToProps = {
    updateShippingAddress,
    addShippingAddress
}

export default connect(mapStateToProps, mapDispatchToProps)(ShippingAddressForm);